<div [ngStyle]=" essentialsService.isMobile? {'width':'60vw'} : {'width':'40vw'}" >
  <mat-dialog-content>
  <h3 style="font-size: 16px;" class="mat-h3 basicCss">Learn {{ defaultData.classType }}</h3>
  <form [formGroup]="classesForm" class="basicCss">
    <div>
    <mat-form-field appearance="outline" required>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" placeholder="Your name" required>
      <mat-error *ngIf="classesForm.controls.name.errors?.required">First name is required</mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input #emailInput matInput type="email" formControlName="email" placeholder="Email address" required>
        <mat-error *ngIf="classesForm.controls.email.errors?.required">Email address is required</mat-error>
        <mat-error *ngIf="classesForm.controls.email.errors?.pattern">Please enter a valid email address</mat-error>
    </mat-form-field>
  </div>
  <div>
    <div class="flex-container-horizontal" style="align-items: center; justify-content: space-evenly;">
      <button style="align-self: center;" class="big-button" mat-stroked-button mat-dialog-close>Go back</button>
      <button style="align-self: center;" [disabled]="!(classesForm.get('name').valid && classesForm.get('email').valid)" class="big-button" mat-stroked-button mat-dialog-close (click)="enrollForClasses()">Sign Up</button>
    </div>
  </div>
  </form>
</mat-dialog-content>
</div>
