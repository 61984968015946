<mat-toolbar [ngClass]="essentialsService.toolbarClass" #toolbar style="background-color: white;">
  <span style="align-content: flex-start;">
  <button
    type="button"
    aria-label="Toggle sidenav"
    mat-icon-button
    onclick="this.blur()"
    (click)="drawer.toggle()"
    *ngIf="essentialsService.isMobile | async">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  </span>
  <span style="text-align: center;">
  <a href="#" class="remove-color-from-link" routerLink='/home'>
    <h3 style="margin-bottom: 0;" class = "logo-letter-spacing-normal mat-h3 basicCss" *ngIf="essentialsService.isMobile | async;else logoNormal">kalimann studio</h3>
  <ng-template #logoNormal>
    <h1 style="padding-top: 48px; font-size: 34px;" class = "logo-letter-spacing-normal">kalimann studio</h1>
  </ng-template>
  </a>
</span>
<span *ngIf="(essentialsService.isMobile | async)" [ngStyle]=" !(essentialsService.isMobile | async) ? {'padding-top': '32px'} : {}" style="align-content: flex-end;">
  <button
    type="button"
    aria-label="Cart"
    mat-icon-button
    onclick="this.blur()"
    (click)="goToCart()">
    <mat-icon matBadgeSize="small" matBadgeHidden="{{ numberOfCartItems <= 0 }}" matBadge="{{ numberOfCartItems }}" matBadgeColor="warn" aria-label="Go to cart icon">shopping_cart</mat-icon>
    <span *ngIf="numberOfCartItems > 0" class="cdk-visually-hidden">
      Cart icon showing the number of cart items
    </span>
  </button>
</span>
</mat-toolbar>
<mat-toolbar class="sticky-toolbar" *ngIf="!(essentialsService.isMobile | async)" style="background-color: white;">
  <div class="grid-wrapper" style="margin-top: -8px;">
    <nav class="nav-area" mat-tab-nav-bar mat-align-tabs="center">
      <a mat-tab-link
         href="#"
         [routerLink]="'/home'"
         routerLinkActive #homeLink="routerLinkActive"
         [active]="homeLink.isActive">
        HOME
      </a>
      <a mat-tab-link
          href="#"
          [routerLink]="'/about'"
          routerLinkActive #aboutLink="routerLinkActive"
          [active]="aboutLink.isActive">
        ABOUT
      </a>
      <a mat-tab-link
         href="#"
         #shopNav
         #button
         (click)="goToDefaultShop()"
         [routerLink]="'/shop'"
         routerLinkActive #shopLink="routerLinkActive"
         [active]="shopLink.isActive"
         [matMenuTriggerFor]="menu"
         #levelOneTrigger="matMenuTrigger" (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)" style="z-index:1050">
        SHOP
      </a>
      <a mat-tab-link
         href="#"
         [routerLink]="'/gallery'"
         routerLinkActive #galleryLink="routerLinkActive"
         [active]="galleryLink.isActive">
        GALLERY
      </a>
      <!-- <a mat-tab-link
          href="#">
        Cart ({{ numberOfCartItems }})
      </a> -->
    </nav>
    <!-- <a mat-list-item href="#" routerLink='/home' routerLinkActive="active">Home</a>
    <a mat-list-item href="#" routerLink='/about'>About</a>
    <a mat-list-item href="#" routerLink='/shop'>Shop</a> -->
    <mat-menu #menu="matMenu" [overlapTrigger]="false" [hasBackdrop]="false">
      <span (mouseenter)="menuenter()" (mouseleave)="menuLeave(levelOneTrigger, button)"
       >
          <a mat-menu-item class="bold-hover" style="width: 128px; text-align: center;"[routerLink]="['/shop/',link]" *ngFor="let link of shopCategories"><span>{{ link }} </span></a>
      </span>
    </mat-menu>
    <button
      class="shopping-cart-icon-area"
      *ngIf="!(this.router.url.includes('cart') || this.router.url.includes('checkout'))"
      type="button"
      aria-label="Cart"
      mat-icon-button
      onclick="this.blur()"
      (click)="goToCart()">
      <mat-icon matBadgeHidden="{{ numberOfCartItems <= 0 }}" matBadge="{{ numberOfCartItems }}" matBadgeColor="warn" aria-label="Go to cart icon">shopping_cart</mat-icon>
      <span *ngIf="numberOfCartItems > 0" class="cdk-visually-hidden">
        Cart icon showing the number of cart items
      </span>
    </button>
  </div>
  <div>
  <mat-divider style="width: 100%;"></mat-divider>
</div>
</mat-toolbar>
<mat-sidenav-container class="side-nav">
  <mat-sidenav #drawer
      [fixedTopGap] = "48"
      [fixedInViewport] = "true"
      [opened]="false"
      style="display: inline;"
      [attr.role]="(essentialsService.isMobile | async) ? 'dialog' : 'navigation'"
      [mode]="(essentialsService.isMobile | async) ? 'over' : 'side'"
      mode="over">
    <mat-nav-list>
      <a mat-list-item href="#" routerLink='/home' routerLinkActive="active">Home</a>
      <a mat-list-item href="#" routerLink='/about' routerLinkActive="active">About</a>
        <mat-expansion-panel #expPanel [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-size: 16px;">
            <h1 style="font-size: 23px;" class="h3-nopad">Shop</h1>
          </mat-panel-title>
        </mat-expansion-panel-header>
          <mat-nav-list>
            <a mat-list-item (click)="expPanel.expanded = false" mat-list-item [routerLink]="['/shop/',link]" routerLinkActive="active" *ngFor="let link of shopCategories"><span ><div style="line-height: 26px;">{{ link }}</div></span></a>
          </mat-nav-list>
        </mat-expansion-panel>
      <a mat-list-item href="#" routerLink='/gallery' routerLinkActive="active">Gallery</a>
      <!-- <a mat-list-item href="#" routerLink='/cart' routerLinkActive="active">Cart ..{{ numberOfCartItems }}</a> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- Inside this tag, render the different pages of our app -->

    <router-outlet></router-outlet>

    <br/>
    <br/>
    <div class = "footer">
      <h3 class = "logo-letter-spacing-normal basicCss mat-h3">kalimann  studio</h3>
      <br/>
      <br/>
      <p class="mat-h4 basicCss"><a href="tel:+918830167107" style="text-decoration: none; color: #000000">Phone: 8830167107</a></p>
      <br/>
      <p class="basicCss"><a class="remove-color-from-link mat-h4" href="mailto:contact@kalimannstudio.com" style="text-align: center;">contact&#64;kalimannstudio.com</a></p>
      <br/>
      <p class="mat-caption basicCss">PLOT NO.58, LAKSHMI PRASAD</p>
      <p class="mat-caption basicCss">SUDARSHAN NAGAR</p>
      <p class="mat-caption basicCss">CHINCHWAD</p>
      <p class="mat-caption basicCss">PUNE, MH, India - 411033</p>

      <p style="text-align: center;">
        <a class="remove-color-from-link mat-caption basicCss" style="padding-left: 8px; padding-right: 8px;" href="#" routerLink='/termsConditionsPolicy'>Terms and Conditions</a>
        <a class="remove-color-from-link mat-caption basicCss" style="padding-left: 8px; padding-right: 8px;" href="#" routerLink='/shippingPolicy'>Shipping Policy</a>
        <a class="remove-color-from-link mat-caption basicCss" style="padding-left: 8px; padding-right: 8px;" href="#" routerLink='/faqs'>FAQs</a>
        <a class="remove-color-from-link mat-caption basicCss" style="padding-left: 8px; padding-right: 8px;" href="#" routerLink='/returnsPolicy'>Returns</a>
      </p>
      <br/>
      <p style="text-align: center;">
        <a href="https://www.facebook.com/kalimannstudio">
          <mat-icon svgIcon="facebook_logo" aria-hidden="false" style="padding-left: 8px; padding-right: 8px;" aria-label="Kalimann Facebook page link"></mat-icon>
        </a>
        <a href="https://www.instagram.com/kalimannstudio">
          <mat-icon svgIcon="instagram_log" aria-hidden="false" style="padding-left: 8px; padding-right: 8px;" aria-label="Kalimann Instagram page link"></mat-icon>
        </a>
      </p>

    </div>
    <br/>
    <!-- <div *ngFor = "let number of numbers" class="number">
      {{ number }}
    </div>   -->
  </mat-sidenav-content>
</mat-sidenav-container>
