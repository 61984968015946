import { Injectable, OnInit, Inject, PLATFORM_ID } from '@angular/core';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay, combineAll, mergeMap, mapTo, switchMap } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { NewsletterSubscriber } from '../interfaces/newsletterSubscriber.interface';
import { appUserData } from '../interfaces/appUserData.interface';
import { appUserLocationDetails } from '../interfaces/appUserLocationDetails.interface';
import { Country } from '../interfaces/country.interface';
import { State } from '../interfaces/state.interface';
import { CurrencyRate } from '../interfaces/currencyRates.interface';

enum ENV {
  DEV,
  PROD,
  LOCAL
};

enum regexPatterns {
  EMAIL = "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"
};

enum address {
  freeIpAddress='https://freegeoip.app/json/',
  freeCountryDetails="https://restcountries.com/v3.1/alpha/",
  assetCountries="../../assets/countries.json",
  assetStates="../../assets/states.json"
}

enum displayMargins{
  desktop = "basic-margin-desktop",
  mobile = "basic-margin-mobile"
};

enum displayToolbar{
  mobile = "mobile-logo-toolbar",
  desktop = "desktop-logo-toolbar"
};

@Injectable({
  providedIn: 'root'
})

export class EssentialsService implements OnInit {

  // Observable to listen to screen size changes
  isMobile : Observable<boolean>;

  // ENV variable Change to change ENV
  env : ENV = ENV.PROD;

  //http call variables
  htppOngoingRequestStatus: string = '';

  //displayMargins
  marginClass: string = "basic-margin-desktop";

  //display toolbar
  toolbarClass : string = 'desktop-logo-toolbar';

  //User location data object
  userLocationData: appUserLocationDetails;

  //User location data object
  userIpData: appUserData;

  //Set toolbar height
  toolbarHeight: number = 0;

  //Currency rate
  currencyRate: CurrencyRate = {'currency': 'INR' , 'rate': 1};

  //currency rate decimal formatter
  currencyRateFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  //decimal formatter
  decimalFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  constructor(private breakpointObserver: BreakpointObserver,
    public httpClient: HttpClient, @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router) {

    //Start looking for handset switch changes:
    this.isMobile = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => {
        // //console.log("Mode: ", result.matches);
        if(result.matches){
          this.marginClass = displayMargins.mobile;
          this.toolbarClass = displayToolbar.mobile;
          document.querySelector("body").style.cssText = "--form-field-width: 55vw";
        } else {
          this.marginClass = displayMargins.desktop;
          this.toolbarClass = displayToolbar.desktop;
          document.querySelector("body").style.cssText = "--form-field-width: 25vw";
        }
        return result.matches;
      }),
      // shareReplay()
    );

   }

  ngOnInit(){

  }

  getRegexPatterns(){
    return regexPatterns;
  }

  getBaseQuery() : String {
    switch(this.env){
      case ENV.DEV:
        return 'http://localhost:3000';
        break;
      case ENV.PROD:
        // return 'https://kalimannstudio.com/api';
        return 'https://u0con50ug0.execute-api.ap-south-1.amazonaws.com/prod'
        break;
      case ENV.LOCAL:
        return 'https://kalimannstudio.com/api';
        break;
      default:
        return 'http://localhost:3000';
    }
  }

  makeGetcall(url: string): Observable<any>{
    this.htppOngoingRequestStatus = 'Loading...';
    var results : any;
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    const requestOptions = {
      headers: new Headers(headerDict),
    };

    //console.log('Making get call...');
    const apiCall = this.httpClient.get(url, {headers: headerDict});
    return apiCall;
  }

  makePostcall(url: string, formData: any): Observable<any>{
    this.htppOngoingRequestStatus = 'Loading...';
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    //console.log('Making post call... with url', url,' data: ', formData);
    const apiCall = this.httpClient.post(url, formData);
    return apiCall;
  }

  addSubscriber(formData: NewsletterSubscriber, activity: String): Observable<any>{
    formData["activity"] = activity.replace(/\s/g, "")
    //console.log('FormData: ',JSON.stringify(formData));
    this.htppOngoingRequestStatus = 'Loading...';
    const queryUrl = `${this.getBaseQuery()}/newsLetterSubscriber`;
    // let data : FormData = new FormData();
    // formData.forEach((key: string, value: string) => {
    //   data.append(key,value);
    // });
    return this.makePostcall(queryUrl, formData);
  }


  getUserLocationData() {
    try{
      if(window.localStorage && localStorage.getItem('sessionKalimannUserData') && localStorage.getItem('sessionKalimannUserIpData')){
        this.userLocationData = JSON.parse(localStorage.getItem('sessionKalimannUserData'));
        this.userIpData = JSON.parse(localStorage.getItem('sessionKalimannUserIpData'));
      }
    } catch (ex){
      //console.log('Nothing from local storage for location')
    }
    if(!this.userLocationData){
      //console.log("Fetching suer location data...")
      this.fetchUserLocation().subscribe((result: appUserLocationDetails)=>{
        this.setUserLocationData(result);
        this.currencyRate.currency = result.currencies[0].code;
        this.setCurrencyRate(this.currencyRate);
      });
    } else {
      //console.log('User location details already present.', this.userLocationData);
      this.currencyRate.currency = this.userLocationData.currencies[0].code;
      this.setCurrencyRate(this.currencyRate);
    }
  }

  setUserLocationData(locationData: appUserLocationDetails){
    this.userLocationData = locationData;
    if(window.localStorage){
      localStorage.setItem("sessionKalimannUserData",JSON.stringify(this.userLocationData));
      localStorage.setItem("sessionKalimannUserIpData",JSON.stringify(this.userIpData));
    }
  }

  fetchUserLocation() : Observable<appUserLocationDetails> {
    //console.log('Getting user ip and country details from web call...');
    return this.httpClient.get<appUserData>(address.freeIpAddress).pipe(
      switchMap((userIpLocation: appUserData) => {
        //console.log("Data from IP location:", userIpLocation)
        var countryTwoLetterCode: string ='IN';
        if(userIpLocation){
          this.userIpData = userIpLocation;
          //console.log('Data from IP:' ,userIpLocation);
          countryTwoLetterCode = userIpLocation.country_code;
        }
        return this.httpClient.get<appUserLocationDetails>(address.freeCountryDetails + countryTwoLetterCode);
      })
    );
  }

  setCurrencyRate(currencyRates: CurrencyRate){
    // console.log('Fetching currency rate for currency: ',currencyRates.currency);
    const queryUrl = `${this.getBaseQuery()}/getCurrencyRate`;
    this.makePostcall(queryUrl,currencyRates).subscribe((rate: number) => {
      // console.log('Rate received is: ', rate);
      this.currencyRate.rate = rate;
    });
  }

  getCountries(): Observable<Country[]> {
    return this.makeGetcall(address.assetCountries);
  }

  getStatesForCountry(countryId: number): Observable<State[]> {
    return this.makeGetcall(address.assetStates).pipe(
      map((states : State[]) => states.filter(eachState => eachState.country_id == countryId)),
    );
  }

  getUsersCountry(): Observable<Country>{
    return this.makeGetcall(address.assetCountries).pipe(
      map((countries : Country[]) => countries.find(eachCountry => eachCountry.sortname == this.userLocationData.alpha2Code ?? 'IN')),
    );
  }

  getUsersState(): Observable<State>{
    return this.makeGetcall(address.assetStates).pipe(
      map((states : State[]) => states.find(eachState => eachState.name.toLowerCase().replace(" ","") == this.userIpData.region_name.toLowerCase().replace(" ",""))),
    );
  }

  // writeSessionItemsToLocalStorage(){
  //   if(window.localStorage){
  //     localStorage.setItem("sessionKalimannUserInfo",JSON.stringify(this.sessionCartItemsStream.value));
  //   }
  // }

  goHome(){
    this.router.navigate(['']);
  }

  goShop(){
    this.router.navigate(['shop']);
  }

  goDefaultShop(){
    this.router.navigate(['shop/Cups']);
  }

  goToCart(){
    this.router.navigate(['cart']);
  }

}
