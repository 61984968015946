import { Component, Input, OnInit } from '@angular/core';
import { TransactionAddress } from '../../interfaces/transactionAddress.interface';

@Component({
  selector: 'app-live-order-item',
  templateUrl: './live-order-item.component.html',
  styleUrls: ['./live-order-item.component.scss', '../../app.component.scss']
})
export class LiveOrderItemComponent implements OnInit {
  @Input() liveOrderItem: TransactionAddress = {
    txnid : '',
    address : '',
    city : '',
    state : '',
    pinCode : '',
    productInfo : '',
    productInfoAggArray : [{
        itemChosenQuantity: '',
        itemId: ''
    }],
    productInfoAllArray : [{
        boxed_status: '',
        box_required: '',
        itemId: ''
    }],
    fullAddress: '',
    paymentStatus: '',
    phone: '',
    numberOfBoxes: '',
    name: '',
    email: '',
    shippingMethod: '',
    totalAmount: 0,
    shippingAmount: 0,
    sys_creation_date: ''
  };
  constructor() { }

  ngOnInit(): void {
  }

}
