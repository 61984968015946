import { Component, OnInit } from '@angular/core';
import { EssentialsService } from '../service/essentials.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  step = 0;

  constructor(public essentialsService: EssentialsService) { }

  ngOnInit(): void {
  }

  setStep(index: number) {
    this.step = index;
  }

}
