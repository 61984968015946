import { Component, OnInit } from '@angular/core';
import { EssentialsService } from '../service/essentials.service';

@Component({
  selector: 'app-returns',
  templateUrl: './returns.component.html',
  styleUrls: ['./returns.component.css','../app.component.scss']
})
export class ReturnsComponent implements OnInit {

  constructor(public essentialsService: EssentialsService) { }

  ngOnInit(): void {
  }

}
