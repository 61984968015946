import { Injectable } from '@angular/core';

import { EssentialsService } from './essentials.service';

import { ShopItem } from '../interfaces/shop-item.interface';
import { Observable, BehaviorSubject } from 'rxjs';

import { map, shareReplay } from 'rxjs/operators';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { ShopItemComponent } from '../shop/shop-item/shop-item.component';
import { query } from '@angular/animations';
import { ActualOrderItemQuantity } from '../interfaces/actualOrderItemQuantity.interface';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  baseQuery : String = '';
  latestViewedShopItem: ShopItem;
  sessionCartItems: Array<ShopItem> = [];

  public sessionCartItemsStream = new BehaviorSubject<Array<ShopItem>>([]);

  constructor(private essentialsService: EssentialsService,
    private http: HttpClient) {
    this.baseQuery = essentialsService.getBaseQuery();
  }

  getSingleShopItem(shopItemId: string): Observable<ShopItem>{
    const params: string = [
      `query=shopItem`,
      `id=${shopItemId}`
    ].join('&');
    const queryUrl = `${this.baseQuery}/shopItem/${shopItemId}`;
    //console.log(`Base getSingleShopItemURL: ${queryUrl}`);
    return this.essentialsService.makeGetcall(queryUrl);
  }

  getShopItemsByCategory(category: string): Observable<ShopItem[]>{
    const params: string = [
      `query=shopItem`,
      `id=${category}`
    ].join('&');
    const queryUrl = `${this.baseQuery}/shopItemByCategory/${category}`;
    //console.log(`Base getSingleShopItemURL: ${queryUrl}`);
    return this.essentialsService.makeGetcall(queryUrl);
  }

  getAllShopItems(): Observable<ShopItem[]> {
    const params: string = [
      `q=${this.baseQuery}`
    ].join('&');
    const queryUrl = `${this.baseQuery}/shopItem`;
    //console.log(`Base URL: ${queryUrl}`);
    const fromStorage = '../../assets/db.json';
    return this.essentialsService.makeGetcall(queryUrl);
  }

  setSessionCartItems(shopItems: Array<ShopItem>){
    //console.log('Set new Cart components!')
    this.sessionCartItems = shopItems;
    this.sessionCartItemsStream.next(shopItems);
  }

  setLatestViewedShopItem(shopItem: ShopItem){
    this.latestViewedShopItem = shopItem;
  }

  getLatestViewedShopItem(): ShopItem{
    return this.latestViewedShopItem;
  }

  addSessionShopItemToCart(item: ShopItem): number{
    item.inCart = true;
    //console.log('Quantity chosen',item.quantityChosen);
    if(!item.quantityChosen){
      item.quantityChosen=1;
    }
    this.sessionCartItems.push(item);
    this.sessionCartItemsStream.next(this.sessionCartItems);
    this.writeSessionItemsToLocalStorage();
    return this.sessionCartItems.length;
  }

  getNumItemsOfSessionInCart(): number{
    return this.sessionCartItems.length;
  }

  removeSessionShopItemFromCart(itemId: string): number{
    // console.log('ID received to remove: ', itemId);
    // console.log(JSON.stringify(this.sessionCartItems));
    this.sessionCartItems = this.sessionCartItems.filter((item) => item.id != itemId);
    this.sessionCartItemsStream.next(this.sessionCartItems);
    this.writeSessionItemsToLocalStorage();
    return this.sessionCartItems.length;
  }

  getAndSetShopItemsFromLocalStorage(){
    if(window.localStorage){
      if(localStorage.getItem("sessionKalimannCartItems")){
        var sessionCartItems  = JSON.parse(localStorage.getItem("sessionKalimannCartItems"));
        //console.log('Got local storage cart items',sessionCartItems);
        this.setSessionCartItems(sessionCartItems);
      }
    }
  }

  filterShopItemsIfInCartAndFactorRate(items: ShopItem[]): ShopItem[]{
    //console.log('Factoring all shop item rates according to currency..');
    //console.log('Currency rate: ' + this.essentialsService.currencyRate.rate);
    items.map(item => {
        //console.log('Factoring currency rate for item id:' + item.item_name + ' with price: ' + item.price);
        // console.log('currency rate: ' + (1/this.essentialsService.currencyRate.rate) + " item price: " + item.price);
        var newRateAccToCurrenctRate = ((1/this.essentialsService.currencyRate.rate) * item.price).toFixed(2);
        item.price = parseInt(newRateAccToCurrenctRate);
        //console.log('Final item price: ' + item.price);
      this.sessionCartItemsStream.getValue().forEach(value => {
        if(value.id === item.id){
          //console.log('Result id:',value.id,'Cart item id:',item.id);
          //console.log('Matching value from cart',value.id);
          item.inCart = true;
          item.quantityChosen = value.quantityChosen;
        }
      });
    });
    return items;
  }

  filterShopItemIfInCart(items: ShopItem[]): ShopItem[] {
    items.map(item => {
    this.sessionCartItemsStream.getValue().forEach(value => {
      if(value.id === item.id){
        //console.log('Result id:',value.id,'Cart item id:',item.id);
        //console.log('Matching value from cart',value.id);
        item.inCart = true;
        item.quantityChosen = value.quantityChosen;
      }});
    });
      return items;
  }

  setShopItemQuantity(id: string, quantity: number){
    var sessionCartItems = this.sessionCartItemsStream.getValue();
    sessionCartItems.map(item => {
      if(item.id === id){
        //console.log('Setting quantity chosen for id: ',item.id,' quantity as :  ', quantity);
        item.quantityChosen = quantity;
      }
    });
    //console.log('Cart items after quantity change: ', sessionCartItems);
    this.sessionCartItemsStream.next(sessionCartItems);

    this.writeSessionItemsToLocalStorage();
  }

  checkIfShopItemAvailable(shopItemId: string) : Observable<number>{
    const params: string = [
      `q=${this.baseQuery}`
    ].join('&');
    const queryUrl = `${this.baseQuery}/checkIfShopItemAvailable/${shopItemId}`;
    //console.log(`Base URL: ${queryUrl}`);
    return this.essentialsService.makeGetcall(queryUrl);
  }

  getShopItemIdListFromSessionCart(): Array<string>{
    var idList = [];
    this.sessionCartItemsStream.getValue().forEach(shopItem => {
      idList.push(shopItem.id);
    });
    return idList;
  }

  getTotalAmountFromSessionCart(): number{
    var totalAmount = 0;
    //console.log('Get total cost method called');
    this.sessionCartItemsStream.getValue().forEach(item => {
      //console.log('Item price:',item.price, ' Item quantity: ', item.quantityChosen);
      totalAmount += (item.price * item.quantityChosen);
      totalAmount = Number.parseFloat(totalAmount.toFixed(2));
      //console.log('Total cost:', totalAmount);
    })
    return totalAmount;
  }

  getTotalWeightFromSessionCart(): number{
    var totalWeight = 0;
    //console.log('Get total weight method called');
    this.sessionCartItemsStream.getValue().forEach(item => {
      //console.log('Item weight:',item.weight, ' Item quantity: ', item.quantityChosen);
      totalWeight += (item.weight * item.quantityChosen);
      totalWeight = Number.parseFloat(totalWeight.toFixed(2));
      //console.log('Total weight:', totalWeight);
    })
    return totalWeight;
  }

  getActualItemQuantityFromSessionCart(): ActualOrderItemQuantity[]{
    var actualItemQuantityArray : ActualOrderItemQuantity[] = [] ;//= {itemId: "0", itemChosenQuantity: 0};
    this.sessionCartItemsStream.getValue().forEach(shopItem => {
      var actualItemQuantity : ActualOrderItemQuantity = {itemId: "0", itemChosenQuantity: "0"};
      actualItemQuantity.itemChosenQuantity = shopItem.quantityChosen.toString();
      actualItemQuantity.itemId = shopItem.id;

      //add to array
      actualItemQuantityArray.push(actualItemQuantity);

    });
    //console.log('actual Item actualItemQuantityArray: ',actualItemQuantityArray);
    return actualItemQuantityArray;
  }

  removeShopItemzfromCartAfterPurchase(idList: Array<string>){
    idList.forEach((id: string) => {
      // console.log('Removing itemId: ', id);
      this.removeSessionShopItemFromCart(id);
    });
  }

  writeSessionItemsToLocalStorage(){
    if(window.localStorage){
      localStorage.setItem("sessionKalimannCartItems",JSON.stringify(this.sessionCartItemsStream.value));
    }
  }

}
