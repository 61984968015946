<div class="basicCss" noRightClick class = "item-card">
  <mat-card appearance="outlined">
    <button style="float: right;" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon>done</mat-icon>
        <span>Completed</span>
      </button>
    </mat-menu>
    <h4>{{ liveOrderItem.totalAmount }}</h4>
    <h4>{{ liveOrderItem.name }}</h4>
    <h4>{{ liveOrderItem.shippingMethod }}</h4>

  </mat-card>
</div>
