<div class="basicCss basicMargin" [ngClass]="essentialService.marginClass">
  <h3 style="padding-top: 24px; padding-bottom: 32px;">Dashboard Login</h3>
  <form [formGroup]="dashboardLoginForm">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Email ID</mat-label>
        <!-- <span matPrefix>+{{ userData.callingCodes[0] }} &nbsp;</span> -->
        <input name="email" matInput type="tel" formControlName="email" required>
        <mat-error *ngIf="dashboardLoginForm.get('email').errors?.required">Email required</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Enter your password</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" name="password" formControlName="password">
        <mat-error *ngIf="dashboardLoginForm.get('password').errors?.required">Password required</mat-error>
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-buttons" style="margin-top: 8px;">
      <button [disabled]="!dashboardLoginForm.valid" class="big-button" mat-stroked-button (click)="login()">
        Login
      </button>
    </div>

  </form>
</div>
