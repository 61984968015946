import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransactionAddress } from '../interfaces/transactionAddress.interface';
import { EssentialsService } from './essentials.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  baseQuery: String = "";

  constructor(private essentialsService: EssentialsService) {
    this.baseQuery = essentialsService.getBaseQuery();
  }

  login(email:string, password:string ) {
      const queryUrl = `${this.baseQuery}/login`;
      return this.essentialsService.makePostcall(queryUrl,{email,password});
  }

  setSession(authResult) {

    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem("expires_at", JSON.stringify(authResult.expiresIn));

    //console.log('IsloggedIn', this.isLoggedIn());
  }

  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
  }

  public isLoggedIn() {
    return Date.now() < this.getExpiration();
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiresAtMils = JSON.parse(localStorage.getItem("expires_at"));
    //console.log('Expires at mils:' + expiresAtMils + ' Current date milsec: ', Date.now());
    var expireDate = new Date(expiresAtMils);
    //console.log('Expiring at:', new Date(expireDate));
    return expireDate.valueOf();
  }

  getLiveOrders(): Observable<TransactionAddress[]>{
    const queryUrl = `${this.baseQuery}/dashboard/getLiveOrders`;
    return this.essentialsService.makeGetcall(queryUrl);
  }

  getCompletedOrders(): Observable<TransactionAddress[]>{
    const queryUrl = `${this.baseQuery}/dashboard/getCompletedOrders`;
    return this.essentialsService.makeGetcall(queryUrl);
  }
}
