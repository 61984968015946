<div [ngClass]="essentialsService.marginClass" [ngStyle]="!(essentialsService.isMobile | async) ? {'padding-left': '320px','padding-right':'320px'} : {}">

  <h2 class="mat-headline-6 basicCss">Returns</h2>

  <br/>

  <p class="mat-h4">Dear valued customers,</p>
  <p class="mat-h4">Thank you for supporting us.</p>
  <p class="mat-h4">All kalimann studio objects are completely hand made and each piece is unique in its own special way. Therefore, they are not hundred percent identical in terms of its shape, size and colour including when ordering a multiple of any piece, or a set.</p>
  <p class="mat-h4">We take great care in the packing of all objects and sculptures. All pieces are properly bubble/newspaper wrapped and sent out with the fragile check box checked during postage.</p>
  <p class="mat-h4">We are in constant touch with the shipping company here and the shipping service is working smoothly at the moment. However, please beware there could be unexpected delays.</p>
  <p class="mat-h4">Refunds and cancellations can only be processed before an order is dispatched and it should be done within a week of placing the order. </p>
  <p class="mat-h4">The postage charges will not be refunded for any cancellation. We do not provide returns and exchanges as our pieces are fragile and can succumb to breakage while being sent back to us by the customers. Moreover, our pieces are inclusive of shipping and postage charges from our end. </p>
  <p class="mat-h4">Therefore, in case a return is mutually agreed upon, the customer has to cover the shipping and damages (if any) during return shipping. </p>
  <p class="mat-h4">Once we have received the pieces back safely we will process the refund through a mutually agreed upon payment method, after deducting the postage charges incurred at our end.</p>
  <p class="mat-h4">Thank you for your patience.</p>
  <p class="mat-h4">Please keep well and safe.</p>
  <p class="mat-h4">Best wishes,</p>
  <p class="mat-h4">Kashmira Shreeram</p>
  <p class="mat-h4">(Founder Kalimannstudio)</p>

</div>
