<div class="basicCss" [ngClass]="essentialService.marginClass">
  <h3 style="padding-bottom: 16px;">Dashboard</h3>
  <mat-expansion-panel style="margin-top: 8px; margin-bottom: 8px;" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ selectedDashboardModule }}
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
      [(ngModel)]="selectedDashboardModule"
      (change)="loadSelectedModule()">
      <mat-radio-button class="example-radio-button" *ngFor="let module of availableModules" [value]=" module ">
        {{ module }}
      </mat-radio-button>
    </mat-radio-group>
  </mat-expansion-panel>

  <!-- Live orders module -->
  <div class="flex-container-horizontal" *ngIf=" ((selectedDashboardModule) === 'Live Orders' &&  liveOrdersList.length > 0);">
    <div style="align-items: start stretch;" *ngFor="let item of liveOrdersList" [@simpleFadeAnimation]="'in'">
      <app-live-order-item [liveOrderItem] = "item"></app-live-order-item>
    </div>
  </div>

  <!-- Completed orders -->
  <div class="flex-container-horizontal"  *ngIf=" ((selectedDashboardModule) === 'Completed Orders' &&  completedOrdersList.length > 0);">
    <div style="align-items: start stretch;" *ngFor="let item of completedOrdersList" [@simpleFadeAnimation]="'in'">
      <app-completed-order-item [liveOrderItem] = "item"></app-completed-order-item>
    </div>
  </div>

  <!-- Nothing to display -->
  <mat-grid-list cols="2" *ngIf ="noResults">
    <mat-grid-tile [colspan]="2">
      <h3 class="basicCss" mat-h3>
        ...
      </h3>
    </mat-grid-tile>
  </mat-grid-list>
</div>
