<div class="container">
  <div class="img-container"  *ngFor="let i of slides; let select = index;"
       [ngClass]="{'selected': select == selectedIndex}">
    <img src="{{i}}" alt="{{i}}" >
  </div>

  <!--</div>-->
  <div [ngStyle]="{'transform': 'translateY('+ transform + '%' +')', 'transition': '.8s'}">
    <div class="text-container">
      <div class="page-text" *ngFor="let i of slides;let select = index;" [ngClass]="{'selected': select == selectedIndex}">
        <h3>{{i}}</h3>
      </div>
    </div>
  </div>

</div>

<div class="slider">
  <div class="slide-button-parent-container"  *ngFor="let i of slides; let x =index" (click)="selected(x)" (keypress)="keySelected(x)" >
     <div class="select-box">
      <div class="slide-button" [ngClass]="{'slide-button-select': x == selectedIndex}" >
      </div>
     </div>
  </div>
</div>
