<div [ngClass]="essentialsService.marginClass" >
<mat-card appearance="outlined">
<mat-card-content>
  <div class="flex-container-horizontal" style="justify-content: center; align-items: center;">
    <div *ngIf="!(essentialsService.isMobile | async)" style="flex-grow: 0.4;"></div>
    <div style="flex-grow: 0.1; align-items: center;">
      <img mat-card-lg-image src="../../assets/machiProfilePic.jpg" appDisableRightClick style="clip-path: circle(); align-self: flex-start; justify-self: center;" alt="Kashmira Shreeram">
    </div>
    <div class="flex-container-vertical" style="flex-grow: 0.1; align-items: flex-start;">
        <mat-card-content>
        <h3 class="mat-card-header" style="text-decoration: underline;">
          Curriculum Vitae
        </h3>        
      <h4 style="line-height: 18px;" class="mat-h4">Qualified and worked briefly as an architect in 2016 before I was drawn towards pottery.</h4>
      <h4 style="line-height: 18px;" class="mat-h4">Began my journey by training at Golden Bridge Pottery, Pondicherry in 2017.</h4>
      <h4 style="line-height: 18px;" class="mat-h4">Assisted Veena Chandran in Pune for 10 months (2017-2018).</h4>
      <h4 style="line-height: 18px;" class="mat-h4">Set up studio space at Chinchwad, Pune in November 2018</h4>
      <h4 style="line-height: 18px;" class="mat-h4">Apprenticed with Mike Dodd and Micki Schloessingk (April 2019-August 2019)</h4>
      </mat-card-content>
    </div>
    <div *ngIf="!(essentialsService.isMobile | async)" style="flex-grow: 0.4;"></div>
    </div>
  </mat-card-content>
</mat-card>
</div>
