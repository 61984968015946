<div noRightClick class="padded-section">
  <div>
    <div style="text-align: center; background-size: auto 100%;">
      <a href="#" (click)="goToDefaultShop()" [routerLink]="'/shop'">
      <picture>
        <!-- <source srcset="../../assets/home_img.webp" type="image/webp"> -->
        <source srcset="../../assets/home_img.jpg" type="image/jpeg">
        <img class="home-image" [ngStyle]="(this.essentialServices.isMobile | async)? {'height' : '408px'} : {}" src="../../assets/home_img.jpg"  alt="Kalimann Home Image">
      </picture>
    </a>
    </div>
    &nbsp;
    &nbsp;
    <div class="basicCss">
      <h3 style="letter-spacing: 4px; font-size: 24px;" [ngStyle]="(this.essentialServices.isMobile | async)? {'font-size' : '22px'} : {'font-size' : '20px'}" class="mat-h3 basicCss">HANDCRAFTED POTS</h3>
      <h4 style="font-family: 'Libre Baskerville', serif; letter-spacing: 0.3px;" class="mat-h3 basicCss"><i>by Kashmira Shreeram</i></h4>
      <p style="line-height: 22px;" class="mat-h4 basicCss">High fired stoneware, mostly functional pots. Subtly playful and <br *ngIf="!(this.essentialServices.isMobile | async)" />
        understated.Wheel thrown and hand built.Trying to use local materials<br *ngIf="!(this.essentialServices.isMobile | async)" />
        as slips and part of glazes. Developing ash glazes.Currently gas firing,<br *ngIf="!(this.essentialServices.isMobile | async)" />
        cone 10 reduction.<br>
        Most of my pots are made on the wheel.<br *ngIf="!(this.essentialServices.isMobile | async)">
        I use a light coloured stomeware body that comes from Gujarat, India.<br *ngIf="!(this.essentialServices.isMobile | async)">
        I am an architect turned potter and I am drawn towards forms <br *ngIf="!(this.essentialServices.isMobile | async)">
        that bring out the softness of the medium but their shapes still defined.<br *ngIf="!(this.essentialServices.isMobile | async)">
        I fire in reduction to 1285-1290 °C.<br *ngIf="!(this.essentialServices.isMobile | async)">
        I think that reduction firing to such high temperatures<br *ngIf="!(this.essentialServices.isMobile | async)">
        brings out the qualities of the clay through the glazes and I also like <br *ngIf="!(this.essentialServices.isMobile | async)">
        that the glazes are quieter but have more depth to them.
      </p>

      <!-- <h4 class="mat-h4 basicCss">Wheel thrown and hand built.</h4>
      <h4 class="mat-h4 basicCss">Trying to use local materials as slips and part of glazes. Developing ash glazes.</h4>
      <h4 class="mat-h4 basicCss">Currently gas firing, cone 10 reduction.</h4> -->
    </div>
    &nbsp;
    &nbsp;
    &nbsp;
    &nbsp;
    <mat-divider></mat-divider>
    <br/>
    <!-- <h3 class="mat-h3 basicCss">There is something for everyone.</h3> -->
    <br *ngIf="!(this.essentialServices.isMobile | async)" />
    <mat-card appearance="outlined" class="flex-container-vertical">
      <br/>
      <br/>
      <h3 class="mat-card-header basicCss">Classes</h3>
      <br/>
      <mat-card-content>
      <div class="flex-container-horizontal">
        <div style="flex-grow: 0.4;" (click)="openClassesDialog('wheel throwing.')">
          <div class="image-container" [ngStyle]="(this.essentialServices.isMobile | async)? {'height' : '200px'} : {'max-height': '45vh'}" >
            <img mat-card-image style="object-fit: fill;" src="../../assets/homeWheel.jpg" alt="Wheel Throwing">
          </div>
          &nbsp;
          <h3 style="font-size: 18px;" class="mat-h3 basicCss">Wheel Throwing</h3>
        </div>
        <div style="flex-grow: 0.4;" (click)="openClassesDialog('hand building.')">
          <div class="image-container" [ngStyle]="(this.essentialServices.isMobile | async)? {'height' : '200px'} : {'max-height': '45vh'}" >
            <img mat-card-image style="object-fit: fill;" src="../../assets/handBuilding.jpg" alt="Hand Building">
          </div>
          &nbsp;
          <h3 style="font-size: 18px;" class="mat-h3 basicCss">Hand Building</h3>
        </div>
      </div>
    </mat-card-content>
    </mat-card>
    <br/>
    <mat-card appearance="outlined">
      <br/>
      <br/>
      <h3  class="mat-card-header basicCss">Newsletters</h3>
      <br/>
      <mat-card-content>
      <div style="text-align: center;">
      <form [formGroup]="emailForm" class="basicCss">
        <mat-form-field appearance="outline">
          <mat-label>Your email</mat-label>
          <input #emailInput matInput type="email" formControlName="email" placeholder="Type here">
            <mat-error *ngIf="emailForm.controls.email.errors?.required">Email address is required to subscribe</mat-error>
            <mat-error *ngIf="emailForm.controls.email.errors?.pattern">Please enter a valid email address</mat-error>
        </mat-form-field>
      </form>
      <br/>
      <button style="align-self: center;" [disabled]="!emailForm.valid" class="big-button" mat-stroked-button (click)="subscribeToNewsLetters()">Subscribe</button>
      </div>
    </mat-card-content>
    </mat-card>

  </div>
</div>
