<div [ngClass]="essentialsService.marginClass" [ngStyle]="!(essentialsService.isMobile | async) ? {'padding-left': '320px','padding-right':'320px'} : {}">

  <h2 class="mat-headline-6 basicCss">FAQs</h2>
  <br/>

  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Personal data
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Destination
        </mat-panel-title>
      </mat-expansion-panel-header>

    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Day of the trip
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

  </mat-accordion>

</div>
