import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
})
export class SliderComponent implements OnInit {

  @Input() slides = [];
  selectedIndex: any;
  transform: any;
  sliderFunction= new Observable();

  constructor() {
    this.selectedIndex = 0;
    this.transform = 100;
   }

  ngOnInit(): void {
    //console.log('From slider',this.slides);

  }

  selected(x) {
    this.downSelected(x);
    this.selectedIndex = x;
   }

   keySelected(x) {
    this.downSelected(x);
    this.selectedIndex = x;
  }

   downSelected(i) {
   this.transform =  100 - (i) * 50;
     this.selectedIndex = this.selectedIndex + 1;
     if (this.selectedIndex > 4) {
       this.selectedIndex = 0;
     }
   }

}
