import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeNavComponent } from './home-nav/home-nav.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CartGuard } from './cart/cart.guard';
import { AboutComponent } from './about/about.component';
import { ClassesDialogComponent } from './home-nav/classes-dialog/classes-dialog.component';
import { ShippingComponent } from './shipping/shipping.component';
import { ReturnsComponent } from './returns/returns.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { LoginComponent } from './shop/login/login.component';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { LiveOrderItemComponent } from './dashboard-home/live-order-item/live-order-item.component';
import { FaqComponent } from './faq/faq.component';
import { CompletedOrderItemComponent } from './dashboard-home/completed-order-item/completed-order-item.component';
import { DisableRightClickModule } from './utils/disable-right-click.moduke';

const routes: Routes = [
  { path: '' , redirectTo: '/home', pathMatch:'full' },
  { path: 'home' , component: HomeNavComponent },
  { path: 'about' , component: AboutComponent },
  { path: 'shippingPolicy' , component: ShippingComponent },
  { path: 'returnsPolicy' , component: ReturnsComponent },
  { path: 'termsConditionsPolicy' , component: TermsConditionsComponent },
  { path: 'shop/:category' , loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) },
  { path: 'shop' , loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) },
  { path: 'cart' , loadChildren: () => import('./cart/cart.module').then(m => m.CartModule), canActivate: [CartGuard] },
  { path: 'checkout' , loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule), canActivate: [CartGuard] },
  { path: 'login' , component: LoginComponent },
  { path: 'dashboard/home' , component: DashboardHomeComponent },
  { path: 'faqs' , component: FaqComponent },
  { path: "**" , component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    DisableRightClickModule,
    RouterModule.forRoot(routes,{
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    enableTracing: false,
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [ HomeNavComponent, PageNotFoundComponent, AboutComponent, ClassesDialogComponent, ShippingComponent, ReturnsComponent, TermsConditionsComponent, LoginComponent, DashboardHomeComponent, LiveOrderItemComponent, FaqComponent, CompletedOrderItemComponent];
