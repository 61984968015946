import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EssentialsService } from 'src/app/service/essentials.service';
import { DashboardService } from '../../service/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../../app.component.scss']
})
export class LoginComponent implements OnInit {

  dashboardLoginForm: UntypedFormGroup;
  hide = true;

  constructor(private fb:UntypedFormBuilder,
    private authService: DashboardService,
    private router: Router,
    public essentialService: EssentialsService,
    private _snackBar: MatSnackBar) {
      this.dashboardLoginForm = this.fb.group({
        email: ['',Validators.required],
        password: ['',Validators.required]
      });
    }

    login() {
      const val = this.dashboardLoginForm.value;

      if (val.email && val.password) {
          this.authService.login(val.email, val.password)
              .subscribe(
                  (result) => {
                    //console.log('Result recevied from login api: ', result);
                    if(result.idToken){
                      //console.log('Login api response: ', result);
                      this.authService.setSession(result);
                      this._snackBar.open('Logged in!', '', {
                        duration: 2000,
                        panelClass: ['snackbar-bg-success']
                      });
                      this.router.navigate(['dashboard/home']);
                    } else {
                      //console.log('User not verified! :-(');
                      this._snackBar.open('Invalid credentials :-(', '', {
                        duration: 2000,
                        panelClass: ['snackbar-bg']
                      });
                    }
                  },
                  (error) => {
                    //console.log('User not verified! :-(');
                    this._snackBar.open('Invalid credentials :-(', '', {
                      duration: 2000,
                      panelClass: ['snackbar-bg']
                    });
                  }
              );
      }
    }

  ngOnInit(): void {
  }

}
