import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DashboardService } from '../service/dashboard.service';
import { EssentialsService } from '../service/essentials.service';
import { TransactionAddress } from '../interfaces/transactionAddress.interface';
import {trigger, state, animate, style, transition} from '@angular/animations';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss', '../app.component.scss'],
  animations:[
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

     // the "in" style determines the "resting" state of the element when it is visible.
     state('in', style({opacity: 1})),

     // fade in when created. this could also be written as transition('void => *')
     transition(':enter', [
       style({opacity: 0}),
       animate(500 )
     ]),

     // fade out when destroyed. this could also be written as transition('void => *')
    //  transition(':leave',
    //    animate(100, style({opacity: 0})))
   ])
 ],
})
export class DashboardHomeComponent implements OnInit {

  liveOrdersList : TransactionAddress[] = [];
  completedOrdersList : TransactionAddress[] = [];
  selectedDashboardModule: string= 'Live Orders';
  availableModules: string[] = ['Live Orders','Completed Orders'];
  noResults = false;

  constructor(public essentialService: EssentialsService, public dashboardService: DashboardService, private router: Router, private _snackBar: MatSnackBar) {
    this.loadSelectedModule();

  }

  ngOnInit(): void {

  }

  loadSelectedModule(){
    switch(this.selectedDashboardModule.toLowerCase()){
      case "live orders":
        this.dashboardService.getLiveOrders().subscribe((results) => {
          //console.log('Results for liveOrders: ', results);
          this.liveOrdersList = results;
          this.checkIfResultsMoreThanZero(results);
        },
        (error) => {
          this.goToLogin();
        });
        break;
      case "completed orders":
        //console.log('Calling completed orders..');
        this.dashboardService.getCompletedOrders().subscribe((results) => {
          //console.log('Results for completedOrders: ', results);
          this.completedOrdersList = results;
          this.checkIfResultsMoreThanZero(results);
        },
        (error) => {
          this.goToLogin();
        });
        break;
      default:
        this.dashboardService.getLiveOrders();
    }
  }

  checkIfResultsMoreThanZero(list){
    //console.log('List is empty? ', list.length == 0);
    if(list.length == 0){
      this.noResults = true;
    } else {
      this.noResults = false;
    }
  }

  goToLogin(){
    this._snackBar.open('Please login..', '', {
      duration: 2000,
      panelClass: ['snackbar-bg']
    });
    this.router.navigate(['login']);
  }

}
