import { Component, ViewChild, ElementRef } from '@angular/core';

import { EssentialsService } from '../service/essentials.service'
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ClassesDialogComponent } from './classes-dialog/classes-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home-nav',
  templateUrl: './home-nav.component.html',
  styleUrls: ['./home-nav.component.scss', '../app.component.scss']
})
export class HomeNavComponent {

  @ViewChild('emailInput') emailInput: ElementRef;
  slidesArray = [];
  emailForm = this.fb.group({
    email: ['']
  });
  homeImageHeight: any;

  constructor(public essentialServices : EssentialsService, public fb: UntypedFormBuilder, private snackBar: MatSnackBar, public classesDialog: MatDialog) {
    this.slidesArray.push('');
    //this.slidesArray.push('https://material.angular.io/assets/img/examples/shiba2.jpg');
    this.emailForm.controls.email.setValidators([Validators.required, Validators.pattern(this.essentialServices.getRegexPatterns().EMAIL)]);
  }

  subscribeToNewsLetters(){
    //console.log('Value of subscription form: ',this.emailForm.value);
    this.essentialServices.addSubscriber(this.emailForm.value, "news").subscribe((response) => {
      //console.log('response received from AddNewsSubscriber: ',response);
      switch(response){
        case 1:
          this.performSubscribeChanges('Subscribed to Kalimann!', 1000)
          break;
        case 0:
          //console.log('recevied 0');
          this.performSubscribeChanges('Already subscribed! Thanks :-)', 700);
          break;
        default:
          //console.log('recevied nothing');
          this.showSnackBar('Error, Please try again later', '');
      }
    });
  }

  performSubscribeChanges(message: string, timeout: number){
    this.showSnackBar(message,'');
      setTimeout(()=>{
        //console.log('Patching value to 1');
        this.emailForm.patchValue({
          email: ''
        });
        this.emailForm.controls.email.reset();
        if(!this.essentialServices.isMobile){
          this.emailInput.nativeElement.focus();
        }
      },timeout);
  }

  showSnackBar(msg: string, actionText: string){
    this.snackBar.open(msg, actionText,{
      duration: 3000,
      panelClass: ['snackbar-bg-success']
    })
  }

  openClassesDialog(className: string) {
    const dialogRef = this.classesDialog.open(ClassesDialogComponent, {
      panelClass: 'my-dialog',
      data: {
        classType: className
      },closeOnNavigation: true
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('dialog result received: ', result)
      if(result){
        this.essentialServices.addSubscriber(result, result.classType).subscribe((response) => {
          //console.log('response received from add subscriber: ',response);
          switch(response){
            case 1:
              this.showSnackBar('We will get in touch soon!','');
              break;
              case 0:
              this.showSnackBar('Thanks, we will get in touch asap :-)','');
              break;
            default:
              //console.log('recevied nothing');
              this.showSnackBar('Error, Please try again later', '');
          }
        });
      }
    })
  }

  goToDefaultShop(){
    //console.log('Default shop clciked.')
    this.essentialServices.goDefaultShop();
  }

}
