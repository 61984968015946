<div [ngClass]="essentialsService.marginClass" [ngStyle]="!(essentialsService.isMobile | async) ? {'padding-left': '320px','padding-right':'320px'} : {}">

  <h2 class="mat-headline-6 basicCss">Shipping</h2>

  <br/>

  <p class="mat-body-2">At Kalimannstudio, We have collaborated with few reputed courier service to ship in India & International. Our finished garments are lovingly packaged in durable material to prevent wear-and-tear in transit.</p>

  <p class="mat-body-2">INDIA – 10-15 WORKING DAYS:</p>

  <p class="mat-body-2">We offer free shipping over the bill value INR 5000. Order usually dispatched within 7 working days and it delivered within 15 working days.</p>

  <h3 class="mat-subtitle-1">URGENT SHIPPING:</h3>

  <p class="mat-body-2">Urgent shipping may cost you INR 350 to deliver the product within 2-5 Days.</p>

  <h3 class="mat-subtitle-1">INTERNATIONAL 25-30 WORKING DAYS</h3>

  <p class="mat-body-2">We are shipping around the world, shipping charges are starting from INR 1200 upto INR 2500 as per location & weight. We offer free shipping for international orders above INR 35,000.</p>

  <p class="mat-body-2">Order usually dispatched within 10 working days and it delivered within 30 working days.</p>


  <h3 class="mat-subtitle-1">Please Note:</h3>

  <h3 class="mat-subtitle-1">FOR DELIVERIES OUTSIDE INDIA:</h3>

  <p class="mat-body-2">Non-Availability on Delivery our delivery partners will attempt to deliver the package three times before they return it to us. Please provide complete shipping address including zip code and mobile number. This will help us in delivering your order faster.</p>

  <h3 class="mat-subtitle-1">DUTIES & TAXES FOR INTERNATIONAL ORDERS</h3>

  <p class="mat-body-2">Please also note that the shipping charges are not inclusive of any custom or import duties that may be levied upon reaching the destination country. We cannot control these charges and are unable to estimate what they may be. Please do note Kalimannstudio does not take responsibility if the packet gets held at customs or at any other point in the destination country</p>

  <h3 class="mat-subtitle-1">MODE OF PAYMENTS </h3>

  <p class="mat-body-2">For shipping to India, we accept all major debit & credit cards (including Mastercard, Visa & American Express) and also provide Net Banking options across major banks. For all international transactions, we accept major Credit Cards (including Mastercard, Visa & American Express) and also provide the option to pay using PayPal.</p>

  <p class="mat-body-2">The bank transfer fees/ Paypal charges and any other transaction fees shall be borne by the buyer. Please note that the actual amount credited to our account should match the invoice amount</p>

  <h3 class="mat-subtitle-1">TRACKING PACKAGE</h3>

  <p class="mat-body-2">Orders that have been processed and shipped can be tracked using the consignment/tracking number on the shipping agency’s website. Once your order is shipped, we will mail you your tracking number along with information about the shipping agency.</p>

</div>
