import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { EssentialsService } from 'src/app/service/essentials.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClassesDialog } from 'src/app/interfaces/classesDialog.interface';

@Component({
  selector: 'app-classes-dialog',
  templateUrl: './classes-dialog.component.html',
  styleUrls: ['./classes-dialog.component.css','../../app.component.scss']
})
export class ClassesDialogComponent implements OnInit {

  defaultData: ClassesDialog = {'classType':"",
'name':"",
'email':"",
"mobile": ""}

  classesForm = this.fb.group({
    name: ['',Validators.required],
    email: ['',Validators.required],
    classType: '',
    startDate: [''],
    endDate: [''],
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: ClassesDialog, public dialogRef: MatDialogRef<ClassesDialogComponent>, private fb: UntypedFormBuilder, public essentialsService: EssentialsService) { }

  ngOnInit(): void {
    //console.log('Data from classes: ', this.data.classType);
    this.defaultData = this.data;
    this.classesForm.patchValue({
      classType: this.data.classType
    })
    this.classesForm.controls.email.setValidators([Validators.required, Validators.pattern(this.essentialsService.getRegexPatterns().EMAIL)]);
  }

  enrollForClasses(){
    this.dialogRef.close(this.classesForm.value);
  }

}
