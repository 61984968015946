import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ShopService } from '../service/shop.service';
import { ShopItem } from '../interfaces/shop-item.interface';
import { EssentialsService } from '../service/essentials.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class CartGuard  {

  sessionCartItems: Array<ShopItem> = []

  constructor(public shopService: ShopService, public essentialsService: EssentialsService, private _snackBar: MatSnackBar){
    this.shopService.sessionCartItemsStream.subscribe(value => {
      this.sessionCartItems = value;
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      var validation: boolean = false;

      validation = this.moreThanZeroItemsInCart();
      //console.log('Cart snackbar called, validation value:',validation);
      if(!validation){
        //console.log('Cart snackbar inside called');
        this._snackBar.open('Nothing added to cart.', '', {
          duration: 2000,
          panelClass: ['snackbar-bg']
        });
      }

      return validation;
  }

  moreThanZeroItemsInCart(): boolean{
    return (this.sessionCartItems.length > 0);
  }

}
