import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';

import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';

import { MaterialModule } from './material/material.module';

import { EssentialsService } from './service/essentials.service';

import { SliderComponent } from './generic-components/slider/slider.component';

import { NoRightClickDirective } from '../app/tools/no-right-click.directive';

import { ShopService } from './service/shop.service';
import { CartGuard } from './cart/cart.guard';
import { RouterModule } from '@angular/router';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { STEPPER_GLOBAL_OPTIONS, STEP_STATE } from '@angular/cdk/stepper';
import { HttpInterceptorTool } from '../app/tools/httpInterceptorTool';
import { DisableRightClickModule } from './utils/disable-right-click.moduke';

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    SliderComponent,
    NoRightClickDirective,
  ],
  imports: [
    AppRoutingModule,
    MaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    CommonModule,
    RouterModule,
    DisableRightClickModule
  ],
  providers: [EssentialsService, ShopService, CartGuard, {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},{provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}, { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorTool, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
