import { Component, ViewChild, OnInit, OnDestroy, Renderer2 } from '@angular/core';

import { withLatestFrom, filter } from 'rxjs/operators';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from "@angular/platform-browser";
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';

import { EssentialsService } from './service/essentials.service';
import { ShopService } from './service/shop.service';
import { appUserLocationDetails } from './interfaces/appUserLocationDetails.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    //TODO
    //slideInAnimation
    // animation triggers go here
  ]
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('drawer', { static: true }) sideNavDrawer: MatSidenav;
  toolbarHeight: number = 0;
  numbers = [];
  shopCategories: Array<string> = ['Cups','Mugs','Bowls','Plates','Jars','Vases','Flower Bricks','Teapots'];
  title:'';
  isNotBrowser = false;
  numberOfCartItems: number = 0;
  userData: appUserLocationDetails;

  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;

  constructor(private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer, public essentialsService: EssentialsService,public router: Router, public shopService: ShopService, private ren: Renderer2){

    this.matIconRegistry.addSvgIcon(
      "logo_small",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/kalimannLogo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "facebook_logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/facebookLogo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "instagram_log",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/instagram_l.svg")
    );

    //close side navigation on click from side nagivation
    router.events.pipe(
      withLatestFrom(this.essentialsService.isMobile),
      filter(([a, b]) => b && a instanceof NavigationEnd)
    ).subscribe(_ => {
      //console.log('Got screen change, is mobile: ',_);
      this.sideNavDrawer.close()
    });



    //For page up
    // router.events.pipe(
    //   filter((e: Scroll): e is Scroll => e instanceof Scroll),
    //   observeOn(asyncScheduler)
    // ).subscribe(e => {
    //   if (e.position) {
    //     // backward navigation
    //     viewportScroller.scrollToPosition(e.position);
    //   } else if (e.anchor) {
    //     // anchor navigation
    //     viewportScroller.scrollToAnchor(e.anchor);
    //   } else {
    //     // forward navigation
    //     viewportScroller.scrollToPosition([0, 0]);
    //   }
    // });

    //console.log("Hi there!");

    // for (let i=0; i<1000; i++){
    //   this.numbers.push(i);
    // }

  }
  // ngAfterViewInit(): void {
  //   try{
  //     this.toolbarHeight = this.toolbar.nativeElement.offsetHeight;
  //     this.essentialsService.toolbarHeight = this.toolbarHeight;
  //     //console.log('Toolbar height: ', this.toolbarHeight);
  //   } catch(ex){
  //     //console.log('Error fetching toolbar height: ', ex);
  //   }
  // }

  // prepareRoute(outlet: RouterOutlet) {
  //   return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  // }

  ngOnInit(){

    //console.log('main App Component called:');
    //setting shop items from local storage:
    this.shopService.getAndSetShopItemsFromLocalStorage();

    //getting newly added shopItems:
    this.shopService.sessionCartItemsStream.subscribe((value)=>{
      this.numberOfCartItems = value.length;
    });

    //to close side nagivation window when desktop
    this.essentialsService.isMobile.subscribe(value => {
      //console.log('Got screen change from isMobile, is mobile: ',value);
      this.sideNavDrawer.close();
    });

     //Get users location
     this.essentialsService.getUserLocationData();
  }

  ngOnDestroy(){

  }

  goShop(){
    this.essentialsService.goShop();
  }

  goToCart(){
    this.essentialsService.goToCart();
  }

  //Code for pop up menu trigger:

  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger, button) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        try{
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
        } catch(err){
          //console.log('error on category menu open')
        }
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80)
  }

  buttonEnter(trigger) {
    setTimeout(() => {
      if(this.prevButtonTrigger && this.prevButtonTrigger != trigger){
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
        try{
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
        } catch(err){
          //console.log('error on category menu open')
        }
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        trigger.openMenu();
        try{
          this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
        } catch (err){
          //console.log('error on category menu open')
        }
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
    })
  }

  buttonLeave(trigger, button) {
    setTimeout(() => {
      try{
        if (this.enteredButton && !this.isMatMenuOpen) {
          trigger.closeMenu();
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
        } if (!this.isMatMenuOpen) {
          trigger.closeMenu();
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
          this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
        } else {
          this.enteredButton = false;
        }
      } catch(err){
        //console.log('error on category menu open')
      }
    }, 100)
  }

  nothing(){

  }

  goToDefaultShop(){
    //console.log('Default shop clciked.')
    this.essentialsService.goDefaultShop();
  }

}
